<template>
    <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="累计" name="1">
            <cumulativeView v-if="activeName == '1'" />
        </el-tab-pane>
        <el-tab-pane label="月" name="2">
            <monthView v-if="activeName == '2'" />
        </el-tab-pane>
        <el-tab-pane label="季度" name="3">
            <quarterView v-if="activeName == '3'" />
        </el-tab-pane>
        <el-tab-pane label="年" name="4">
            <yearView v-if="activeName == '4'" />
        </el-tab-pane>
    </el-tabs>
</template>

<script>
import cumulativeView from "./cumulative/HomeView.vue";
import yearView from "./year/HomeView.vue";
import quarterView from "./quarter/HomeView.vue";
import monthView from "./month/HomeView.vue";
export default {
    components: { cumulativeView, yearView, quarterView, monthView },
    data() {
        return {
            activeName: "1",
        };
    },
};
</script>

<style lang="scss" scoped></style>
