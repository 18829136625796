import { charMax, requiredInput, requiredSelect } from "@/rules";
export const rules = {
    key1: [requiredInput(), charMax(20)],
    content: [requiredInput()],
    synchroType: [requiredSelect()],
    releasePlate: [requiredSelect()],
    key2: [
        requiredSelect(),
        {
            trigger: "change",

            validator: (rule, value, callback) => {
                if (!/^\d*$/.test(value + "")) {
                    callback(new Error("排序只能输入0或正整数"));
                } else if ((value + "").length > 5) {
                    callback(new Error("排序字符长度不超过5个字符"));
                } else {
                    callback();
                }
            },
        },
    ],
};
