<template>
    <el-dialog
        center
        v-bind="$attrs"
        v-on="$listeners"
        width="40%"
        :append-to-body="true"
        :destroy-on-close="true"
        :close-on-click-modal="false"
    >
        <el-form
            class="g-el-form"
            :model="ajaxParam"
            :rules="rules"
            size="mini"
            ref="refElForm"
            label-width="120px"
        >
            <el-form-item
                :label="`${'所属公司'}:`"
                placeholder="请选择公司"
                prop="key1"
            >
                <el-input v-model="ajaxParam.key1"></el-input>
            </el-form-item>
            <el-form-item
                :label="`${'车牌号'}:`"
                placeholder="请输入牌号"
                prop="key2"
            >
                <el-input v-model="ajaxParam.key2"></el-input>
            </el-form-item>
            <el-form-item
                :label="`${'车型'}:`"
                placeholder="请选择车型"
                prop="key2"
            >
                <el-input v-model="ajaxParam.key2"></el-input>
            </el-form-item>
            <el-form-item
                :label="`${'生产厂商'}:`"
                placeholder="请选择生产厂商"
                prop="key2"
            >
                <el-input v-model="ajaxParam.key2"></el-input>
            </el-form-item>
            <el-form-item
                :label="`${'购入日期'}:`"
                placeholder="请选择购入日期"
                prop="key2"
            >
                <el-input v-model="ajaxParam.key2"></el-input>
            </el-form-item>
            <el-form-item
                :label="`${'报废年限'}:`"
                placeholder="请选择报废年限"
                prop="key2"
            >
                <el-input v-model="ajaxParam.key2"></el-input>
            </el-form-item>
            <el-form-item
                :label="`${'技术性能'}:`"
                placeholder="请选择技术性能"
                prop="key2"
            >
                <el-input v-model="ajaxParam.key2"></el-input>
            </el-form-item>
            <el-form-item
                :label="`${'车辆行驶线路'}:`"
                placeholder="请选择车辆行驶线路"
                prop="key2"
            >
                <el-input v-model="ajaxParam.key2"></el-input>
            </el-form-item>
            <el-form-item
                :label="`${'上次年检时间'}:`"
                placeholder="请选择上次年检时间"
                prop="key2"
            >
                <el-input v-model="ajaxParam.key2"></el-input>
            </el-form-item>
            <el-form-item
                :label="`${'下次年检时间'}:`"
                placeholder="请选择下次年检时间"
                prop="key2"
            >
                <el-input v-model="ajaxParam.key2"></el-input>
            </el-form-item>
        </el-form>

        <div slot="footer" class="dialog-footer" style="text-align: center">
            <el-button type="primary" size="small" @click="onConfirm"
                >确定</el-button
            >
            <el-button type="info" size="small" @click="onCancel"
                >取消</el-button
            >
        </div>
    </el-dialog>
</template>

<script>
import { rules } from "./rules";
export default {
    computed: {
        rules() {
            return rules;
        },
    },
    title: "操作数据",
    name: "OperatData",
    props: {
        op: {
            type: Object,
            default: () => {
                return {
                    id: "0",
                    // show: false,
                    // row: null,
                    code: "init",
                };
            },
        },
    },

    data() {
        return {
            ajaxParam: {
                key1: "",
                key2: "",
            },
        };
    },
    created() {
        if (this.op.id != "0") this.lookfor();
    },
    methods: {
        onConfirm() {
            this.$refs.refElForm.validate((valid) => {
                if (valid) {
                    this.submitData();
                }
            });
        },
        async lookfor() {
            // 查看详情
            let { code, data } = await this.$api.get(
                `/info/server/escort/web/journalism/${this.op.id}`
            );
            if (code == 200) {
                Object.keys(this.ajaxParam).forEach((key) => {
                    this.ajaxParam[key] = data[key];
                });
            }
        },
        async submitData() {
            // 提交(编辑)数据
            let { code } =
                this.op.id == "0"
                    ? await this.$api.post("", this.ajaxParam)
                    : await this.$api.put(`/${this.op.id}`, this.ajaxParam);
            if (code == 200) {
                this.$emit("query");
                this.onCancel();
            }
        },
        onCancel() {
            this.$emit("close");
        },
    },
};
</script>

<style lang="scss" scoped>
.g-el-form {
    padding: 0 100px;
}
</style>
