<template>
    <div class="dashboard-container">
        <el-row :gutter="20">
            <el-card>
                <el-row type="flex">
                    <!-- <el-col :span="6">
                        <span class="text-primary text-pad-right"
                            >车辆路线：</span
                        >
                        <el-radio-group size="mini" v-model="ajaxParam.radio">
                            <el-radio-button label="累计"></el-radio-button>
                            <el-radio-button label="月"></el-radio-button>
                            <el-radio-button label="季度"></el-radio-button>
                            <el-radio-button label="年"></el-radio-button>
                        </el-radio-group>
                    </el-col> -->
                    <el-col :span="6" v-if="$admin">
                        <span class="text-primary text-pad-right">公司：</span>
                        <GlobalCompanySelect
                            v-model="ajaxParam.companyId"
                            size="mini"
                            @input="queryList"
                            @change="
                                () => {
                                    ajaxParam.lineId = '';
                                    queryList();
                                }
                            "
                            style="width: 250px"
                        />
                    </el-col>
                    <el-col :span="6">
                        <span class="text-primary text-pad-right"
                            >车辆路线：</span
                        >
                        <GlobalLineSelect
                            :companyId="ajaxParam.companyId"
                            v-model="ajaxParam.lineId"
                            size="mini"
                            @input="queryList"
                            style="width: 250px"
                        />
                    </el-col>
                    <el-col :span="6">
                        <span class="text-primary text-pad-right">车型：</span>
                        <GlobalDictSelect
                            parentCode="MOTORCYCLE_TYPE"
                            v-model="ajaxParam.carModels"
                            size="mini"
                            @input="queryList"
                        />
                    </el-col>
                    <el-col :span="6">
                        <span class="text-primary text-pad-right"
                            >关键字查询：</span
                        >
                        <el-input
                            v-model="ajaxParam.searchName"
                            size="mini"
                            class="input searchInput"
                            suffix-icon="el-icon-search"
                            placeholder="请输入车牌号、车辆自编号查询"
                            @input="queryList"
                            style="width: 250px"
                        />
                    </el-col>
                </el-row>
            </el-card>

            <el-card style="margin-top: 20px">
                <el-row>
                    <vxe-toolbar ref="xToolbar" custom>
                        <template v-slot:buttons>
                            <el-row type="flex" justify="between">
                                <el-col>
                                    <!-- <el-button
                                        type="primary"
                                        size="mini"
                                        @click="
                                            op.id = '0';
                                            op.code = 'add';
                                            op.show = true;
                                        "
                                        >添加</el-button
                                    >
                                    <el-button
                                        type="danger"
                                        size="mini"
                                        @click="del([])"
                                        >批量删除</el-button
                                    > -->
                                </el-col>

                                <el-col :span="8">
                                    <GlobalElDatePicker
                                        size="mini"
                                        type="month"
                                        style="margin-right: 10px"
                                        @change="queryList()"
                                        v-model="ajaxParam.startTime"
                                    />
                                    <!-- <el-button
                                        size="mini"
                                        type="primary"
                                        @click="queryList"
                                        >查询</el-button
                                    > -->
                                    <el-button
                                        type="primary"
                                        size="mini"
                                        @click="onRefresh()"
                                        >刷新</el-button
                                    >

                                    <el-button
                                        type="primary"
                                        size="mini"
                                        @click="onDownload"
                                        >导出excel</el-button
                                    >
                                </el-col>
                            </el-row>
                        </template>
                    </vxe-toolbar>

                    <vxe-table
                        @custom="handleCustom"
                        ref="vxeTable"
                        border
                        resizable
                        show-overflow
                        :auto-resize="true"
                        :header-row-style="headerStyle"
                        :row-style="rowStyleFn"
                        align="center"
                        class="vxe-table-element"
                        height="544"
                        :data="records"
                    >
                        <!-- <vxe-table-column
                            type="checkbox"
                            width="60"
                            fixed="left"
                        /> -->

                        <vxe-table-column
                            type="seq"
                            title="序号"
                            field="num"
                            width="50"
                        >
                            <template v-slot="{ rowIndex }">
                                {{ rowIndex + 1 }}
                            </template>
                        </vxe-table-column>

                        <vxe-table-column title="时间" field="statisticsDate">
                            <template v-slot="{ row }">
                                {{ row.statisticsDate }}
                            </template>
                        </vxe-table-column>

                        <vxe-table-column
                            title="车牌号"
                            field="carNum"
                            show-header-overflow
                            show-overflow="title"
                            show-footer-overflow
                        >
                            <template v-slot="{ row }">
                                {{ row.carNum }}
                            </template>
                        </vxe-table-column>

                        <vxe-table-column title="车辆自编号" field="carNo">
                            <template v-slot="{ row }">
                                {{ row.carNo }}
                            </template>
                        </vxe-table-column>

                        <vxe-table-column title="车型" field="carModelName">
                            <template v-slot="{ row }">
                                {{ row.carModelName }}
                            </template>
                        </vxe-table-column>
                        <vxe-table-column
                            title="生产厂商"
                            field="manufacturer"
                            show-header-overflow
                            show-overflow="title"
                            show-footer-overflow
                        >
                            <template v-slot="{ row }">
                                {{ row.manufacturer }}
                            </template>
                        </vxe-table-column>

                        <vxe-table-column title="购入日期" field="purchaseTime">
                            <template v-slot="{ row }">
                                {{ row.purchaseTime }}
                            </template>
                        </vxe-table-column>
                        <vxe-table-column
                            title="所属企业"
                            field="companyName"
                            show-header-overflow
                            show-overflow="title"
                            show-footer-overflow
                        >
                            <template v-slot="{ row }">
                                {{ row.companyName }}
                            </template>
                        </vxe-table-column>
                        <vxe-table-column title="车辆行驶线路" field="carLine">
                            <template v-slot="{ row }">
                                {{ row.carLine }}
                            </template>
                        </vxe-table-column>

                        <vxe-table-column
                            title="行驶里程(公里)"
                            field="drivingMileage"
                        >
                            <template v-slot="{ row }">
                                {{ row.drivingMileage }}
                            </template>
                        </vxe-table-column>
                        <vxe-table-column
                            title="使用油耗(升)"
                            field="fuelConsumption"
                        >
                            <template v-slot="{ row }">
                                {{ row.fuelConsumption }}
                            </template>
                        </vxe-table-column>

                        <vxe-table-column
                            title="使用油耗环比增长量(升)"
                            field="fuelGrowth"
                        >
                            <template v-slot="{ row }">
                                <div v-if="typeof row.fuelGrowth == 'number'">
                                    <span
                                        v-if="row.fuelGrowth > 0"
                                        style="color: #e42626"
                                    >
                                        {{ Math.abs(row.fuelGrowth * 1) }}
                                        <i class="el-icon-top"></i>
                                    </span>
                                    <span v-else-if="row.fuelGrowth == 0">
                                        {{ Math.abs(row.fuelGrowth * 1) }}</span
                                    >
                                    <span v-else style="color: #10c232">
                                        {{ Math.abs(row.fuelGrowth * 1) }}
                                        <i class="el-icon-bottom"></i>
                                    </span>
                                </div>
                            </template>
                        </vxe-table-column>
                        <vxe-table-column
                            title="使用油耗环比增长率(%)"
                            field="fuelGrowthRate"
                        >
                            <template v-slot="{ row }">
                                <div
                                    v-if="typeof row.fuelGrowthRate == 'number'"
                                >
                                    <span
                                        v-if="row.fuelGrowthRate > 0"
                                        style="color: #e42626"
                                    >
                                        {{ Math.abs(row.fuelGrowthRate * 1) }}
                                        <i class="el-icon-top"></i>
                                    </span>
                                    <span v-else-if="row.fuelGrowthRate == 0">
                                        {{
                                            Math.abs(row.fuelGrowthRate * 1)
                                        }}</span
                                    >
                                    <span v-else style="color: #10c232">
                                        {{ Math.abs(row.fuelGrowthRate * 1) }}
                                        <i class="el-icon-bottom"></i>
                                    </span>
                                </div>
                            </template>
                        </vxe-table-column>

                        <vxe-table-column
                            title="平均百公里油耗(升)"
                            field="avgFuelConsumption"
                        >
                            <template v-slot="{ row }">
                                {{ row.avgFuelConsumption }}
                            </template>
                        </vxe-table-column>

                        <vxe-table-column
                            title="平均百公里油耗环比增长量(升)"
                            field="avgFuelGrowth"
                        >
                            <template v-slot="{ row }">
                                <div
                                    v-if="typeof row.avgFuelGrowth == 'number'"
                                >
                                    <span
                                        v-if="row.avgFuelGrowth > 0"
                                        style="color: #e42626"
                                    >
                                        {{ Math.abs(row.avgFuelGrowth * 1) }}
                                        <i class="el-icon-top"></i>
                                    </span>
                                    <span v-else-if="row.avgFuelGrowth == 0">
                                        {{
                                            Math.abs(row.avgFuelGrowth * 1)
                                        }}</span
                                    >
                                    <span v-else style="color: #10c232">
                                        {{ Math.abs(row.avgFuelGrowth * 1) }}
                                        <i class="el-icon-bottom"></i>
                                    </span>
                                </div>
                            </template>
                        </vxe-table-column>

                        <vxe-table-column
                            title="平均百公里油耗环比增长率(%)"
                            field="avgFuelGrowthRate"
                        >
                            <template v-slot="{ row }">
                                <div
                                    v-if="
                                        typeof row.avgFuelGrowthRate == 'number'
                                    "
                                >
                                    <span
                                        v-if="row.avgFuelGrowthRate > 0"
                                        style="color: #e42626"
                                    >
                                        {{
                                            Math.abs(row.avgFuelGrowthRate * 1)
                                        }}
                                        <i class="el-icon-top"></i>
                                    </span>
                                    <span
                                        v-else-if="row.avgFuelGrowthRate == 0"
                                    >
                                        {{
                                            Math.abs(row.avgFuelGrowthRate * 1)
                                        }}</span
                                    >
                                    <span v-else style="color: #10c232">
                                        {{
                                            Math.abs(row.avgFuelGrowthRate * 1)
                                        }}
                                        <i class="el-icon-bottom"></i>
                                    </span>
                                </div>
                            </template>
                        </vxe-table-column>

                        <vxe-table-column
                            title="使用电耗(度)"
                            field="powerConsumption"
                        >
                            <template v-slot="{ row }">
                                {{ row.powerConsumption }}
                            </template>
                        </vxe-table-column>

                        <vxe-table-column
                            title="使用电耗环比增长量(度)"
                            field="powerGrowth"
                        >
                            <template v-slot="{ row }">
                                <div v-if="typeof row.powerGrowth == 'number'">
                                    <span
                                        v-if="row.powerGrowth > 0"
                                        style="color: #e42626"
                                    >
                                        {{ Math.abs(row.powerGrowth * 1) }}
                                        <i class="el-icon-top"></i>
                                    </span>
                                    <span v-else-if="row.powerGrowth == 0">
                                        {{
                                            Math.abs(row.powerGrowth * 1)
                                        }}</span
                                    >
                                    <span v-else style="color: #10c232">
                                        {{ Math.abs(row.powerGrowth * 1) }}
                                        <i class="el-icon-bottom"></i>
                                    </span>
                                </div>
                            </template>
                        </vxe-table-column>
                        <vxe-table-column
                            title="使用电耗环比增长率(%)"
                            field="powerGrowthRate"
                        >
                            <template v-slot="{ row }">
                                <div
                                    v-if="
                                        typeof row.powerGrowthRate == 'number'
                                    "
                                >
                                    <span
                                        v-if="row.powerGrowthRate > 0"
                                        style="color: #e42626"
                                    >
                                        {{ Math.abs(row.powerGrowthRate * 1) }}
                                        <i class="el-icon-top"></i>
                                    </span>
                                    <span v-else-if="row.powerGrowthRate == 0">
                                        {{
                                            Math.abs(row.powerGrowthRate * 1)
                                        }}</span
                                    >
                                    <span v-else style="color: #10c232">
                                        {{ Math.abs(row.powerGrowthRate * 1) }}
                                        <i class="el-icon-bottom"></i>
                                    </span>
                                </div>
                            </template>
                        </vxe-table-column>
                        <vxe-table-column
                            title="平均百公里电耗(度)"
                            field="avgPowerConsumption"
                        >
                            <template v-slot="{ row }">
                                {{ row.avgPowerConsumption }}
                            </template>
                        </vxe-table-column>

                        <vxe-table-column
                            title="平均百公里电耗环比增长量(度)"
                            field="avgPowerGrowth"
                        >
                            <template v-slot="{ row }">
                                <div
                                    v-if="typeof row.avgPowerGrowth == 'number'"
                                >
                                    <span
                                        v-if="row.avgPowerGrowth > 0"
                                        style="color: #e42626"
                                    >
                                        {{ Math.abs(row.avgPowerGrowth * 1) }}
                                        <i class="el-icon-top"></i>
                                    </span>
                                    <span v-else-if="row.avgPowerGrowth == 0">
                                        {{
                                            Math.abs(row.avgPowerGrowth * 1)
                                        }}</span
                                    >
                                    <span v-else style="color: #10c232">
                                        {{ Math.abs(row.avgPowerGrowth * 1) }}
                                        <i class="el-icon-bottom"></i>
                                    </span>
                                </div>
                            </template>
                        </vxe-table-column>
                        <vxe-table-column
                            title="平均百公里电耗环比增长率(%)"
                            field="avgPowerGrowthRate"
                        >
                            <template v-slot="{ row }">
                                <div
                                    v-if="
                                        typeof row.avgPowerGrowthRate ==
                                        'number'
                                    "
                                >
                                    <span
                                        v-if="row.avgPowerGrowthRate > 0"
                                        style="color: #e42626"
                                    >
                                        {{
                                            Math.abs(row.avgPowerGrowthRate * 1)
                                        }}
                                        <i class="el-icon-top"></i>
                                    </span>
                                    <span
                                        v-else-if="row.avgPowerGrowthRate == 0"
                                    >
                                        {{
                                            Math.abs(row.avgPowerGrowthRate * 1)
                                        }}</span
                                    >
                                    <span v-else style="color: #10c232">
                                        {{
                                            Math.abs(row.avgPowerGrowthRate * 1)
                                        }}
                                        <i class="el-icon-bottom"></i>
                                    </span>
                                </div>
                            </template>
                        </vxe-table-column>

                        <!-- <vxe-table-column field="" title="操作" width="120">
                            <template v-slot="{ row }">
                                <el-dropdown size="small" type="primary">
                                    <el-button size="small" type="primary">
                                        操作<i
                                            class="el-icon-arrow-down el-icon--right"
                                        />
                                    </el-button>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item
                                            icon="el-icon-edit"
                                            @click.native="
                                                op.show = true;
                                                op.id = row.id;
                                                op.code = 'edit';
                                            "
                                            >修改
                                        </el-dropdown-item>
                                        <el-dropdown-item
                                            icon="el-icon-view"
                                            @click.native="
                                                op.show = true;
                                                op.id = row.id;
                                                op.code = 'look';
                                            "
                                            >详情
                                        </el-dropdown-item>
                                        <el-dropdown-item
                                            icon="el-icon-delete"
                                            @click.native="del(row.id)"
                                            >下架
                                        </el-dropdown-item>
                                        <el-dropdown-item
                                            icon="el-icon-delete"
                                            @click.native="del(row.id)"
                                            >删除
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </template>
                        </vxe-table-column> -->
                    </vxe-table>

                    <div style="height: 20px"></div>
                    <el-pagination
                        background
                        :current-page.sync="ajaxParam.currentPage"
                        :page-sizes="[5, 10, 15, 20, 50, 100]"
                        :page-size.sync="ajaxParam.pageSize"
                        layout="total, ->, sizes, prev, pager, next, jumper"
                        :total="total"
                        @size-change="queryList"
                        @current-change="queryList"
                    />
                </el-row>
            </el-card>
        </el-row>
    </div>
</template>

<script>
import { bytesToFile } from "@/utils/handleByte";
export default {
    name: "knowledgeList",
    data() {
        return {
            ajaxParam: {
                currentPage: 1,
                pageSize: 20,
                companyId: this.$admin ? "" : this.$companyId, //企业Id
                carModels: "", //车型code
                lineId: "", //路线id
                endTime: "", //结束时间
                startTime: "", //开始时间
                searchName: "", //查询关键词(车牌号、车辆自编号)
                // direction:"ASC" //排序方式，默认降序 DESC, ASC
                // property:""   // 排序字段，默认创建时间
            },

            records: [],
            total: 0,

            op: {
                code: "init", // "look" "edit" "add" 操作code
                show: false,
                id: "0", //id 为0则表示添加
                // row: null, //要操作的列表选中的一项  当前要操作的行
            },

            columns: [
                "num",
                "statisticsDate",
                "carNum",
                "carNo",
                "carModelName",
                "manufacturer",
                "purchaseTime",
                "companyName",
                "carLine",
                "drivingMileage",
                "fuelConsumption",
                "fuelGrowth",
                "fuelGrowthRate",
                "avgFuelConsumption",
                "avgFuelGrowth",
                "avgFuelGrowthRate",
                "powerConsumption",
                "powerGrowth",
                "powerGrowthRate",
                "avgPowerConsumption",
                "avgPowerGrowth",
                "avgPowerGrowthRate",
            ],
        };
    },
    created() {
        this.queryList();
        this.$nextTick(() => {
            // 手动将表格和工具栏进行关联
            this.$refs.vxeTable.connect(this.$refs.xToolbar);
        });
    },
    methods: {
        onRefresh() {
            // 刷新
            Object.keys((key) => {
                this.ajaxParam[key] = "";
            });
            this.ajaxParam.currentPage = 1;
            this.ajaxParam.pageSize = 20;
            this.queryList();
        },
        onClose() {
            this.op.id = "0";
            this.op.code = "init";
            this.op.show = false;
        },
        handleCustom(e) {
            const columns = JSON.parse(
                JSON.stringify(e.$table.$toolbar.columns)
            );

            this.columns = columns
                .filter((item) => item.visible)
                .map((item) => item.property)
                .filter((item) => item);
            console.log(this.columns);
        },
        async onDownload() {
            const ajaxParam = JSON.parse(JSON.stringify(this.ajaxParam));
            let fields = JSON.parse(JSON.stringify(this.columns));
            ajaxParam.fields =
                Array.isArray(fields) && fields.length > 0 ? fields + "" : null;
            let { code, data } = await this.$api.get(
                "/car/server/escort/web/carEnergyConsumptionStatistics/month/export",
                {
                    params: ajaxParam,
                }
            );
            if (code == 200) {
                bytesToFile(data.bytes, data.fileName);
            }
        },
        async queryList() {
            this.ajaxParam.endTime = this.ajaxParam.startTime;
            let { code, data } = await this.$api.get(
                "/car/server/escort/web/carEnergyConsumptionStatistics/month",
                {
                    params: this.ajaxParam,
                }
            );
            if (code === 200) {
                this.records = data.content;
                this.total = ~~data.totalElements;
            }
        },

        del(val) {
            // 删除操作
            let ids = [];
            if (Array.isArray(val)) {
                //批量选择
                ids = this.$refs.vxeTable
                    .getCheckboxRecords()
                    .map((item) => item.id);
                if (ids.length === 0) {
                    this.$message({
                        message: "请选择要删除的数据",
                        type: "warning",
                        center: true,
                    });
                    return null;
                }
            }

            this.$confirm("确认删除吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(async () => {
                    // let { code } = await this.$api.delete(`${val}`);
                    // if (code === 200) {
                    //     this.$message({
                    //         message: "删除成功",
                    //         type: "success",
                    //         center: true,
                    //     });
                    //     this.queryList();
                    // }
                })
                .catch(() => {});
        },
    },
};
</script>

<style lang="scss" scoped>
.dashboard-container {
    box-sizing: border-box;
    padding: 0 10px;
}
::v-deep .vxe-buttons--wrapper {
    margin-top: 12px;
}
</style>
